<template>
  <div
    style="
      height: 85vh;
      overflow: hidden;
      overflow-y: scroll;
      position: relative;
    "
  >
    <div class="flex bewteen aline_center center wrap" style="padding: 20px 0">
      <el-form ref="form" :model="form" label-width="80px" class="flex">
        <div class="flex center wrap" style="align-content: center">
          <div style="width: 30%">
            <el-form-item label="搜索：">
              <el-input
                v-model="form.name"
                placeholder="请输入产品名"
              ></el-input>
            </el-form-item>
          </div>
          <div style="width: 30%">
            <el-form-item label="拟融资额度（万）：" label-width="200px">
              <el-col :span="11">
                <el-input
                  v-model="form.min_quota"
                  placeholder="请输入数字"
                ></el-input>
              </el-col>
              <el-col class="line" :span="2">-</el-col>
              <el-col :span="11">
                <el-input
                  v-model="form.max_quota"
                  placeholder="请输入数字"
                ></el-input>
              </el-col>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="利率范围（%）" label-width="200px">
              <el-col :span="11">
                <el-input
                  v-model="form.min_rate"
                  placeholder="请输入数字"
                ></el-input>
              </el-col>
              <el-col class="line" :span="2">-</el-col>
              <el-col :span="11">
                <el-input
                  v-model="form.max_rate"
                  placeholder="请输入数字"
                ></el-input>
              </el-col>
            </el-form-item>
          </div>
          <div style="width: 30%">
            <el-form-item label="服务地区：">
              <el-select
                v-model="form.region_id"
                placeholder="请选择"
                clearable
                @clear="clearClass"
                style="width: 100%"
              >
                <el-option
                  :value="item.name"
                  v-for="(item, index) in options6"
                  :key="index"
                  @click.native="setAddressId(item.id)"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div style="width: 30%">
            <el-form-item label="产品状态：" label-width="200px">
              <el-select
                v-model="form.cpstatus"
                placeholder="请选择"
                clearable
                @clear="clearCpStatus"
                style="width: 100%"
              >
                <el-option
                  :value="item.value"
                  v-for="(item, index) in options"
                  :key="index"
                  @click.native="setCpStatus(item)"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div style="width: 17%">
            <el-form-item>
              <el-button type="primary" @click.native="searchData"
                >搜索</el-button
              >
              <el-button type="warning" @click="initAdd(subform.file_type)"
                >添加产品</el-button
              >
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>

    <div>
      <el-table
        :data="listsItem"
        style="width: 100%"
        :header-cell-style="{ background: '#e5f3ff', color: '#1A7FD7' }"
      >
        <el-table-column prop="mark" label="编号"> </el-table-column>
        <el-table-column prop="name" label="产品名称"> </el-table-column>
        <el-table-column
          prop="quota"
          label="借款金额 （万元）"
          class-name="red"
        >
        </el-table-column>
        <el-table-column prop="term" label="融资期限 （月）"> </el-table-column>
        <el-table-column prop="rate" label="年利率 （%）" class-name="red">
        </el-table-column>
        <el-table-column prop="reg_name" label="服务地区"> </el-table-column>
        <el-table-column prop="req_num" label="申请企业数量"> </el-table-column>
        <el-table-column prop="agree_num" label="已完成" class-name="blue">
        </el-table-column>
        <el-table-column prop="finance_user" label="联系人"></el-table-column>
        <el-table-column prop="mobile" label="联系电话"></el-table-column>
        <el-table-column prop="is_groud" label="产品状态">
          <template slot-scope="scope">
            <span v-if="scope.row.is_adopt == 0">审核中</span>
            <span v-if="scope.row.is_adopt == 1 && scope.row.is_groud == 1"
              >已上架</span
            >
            <span v-if="scope.row.is_adopt == 1 && scope.row.is_groud == 2"
              >未上架</span
            >
            <span v-if="scope.row.is_adopt == 2">审核不通过</span>
          </template>
        </el-table-column>
        <el-table-column prop="is_operation" label="操作">
          <template slot-scope="scope">
            <span v-if="scope.row.is_adopt == 0" class="success_font">无</span>
            <span
              v-if="scope.row.is_adopt == 1 && scope.row.is_groud == 1"
              class="refrsh_font"
              @click="operationData(scope.row.id, 2)"
              >下架</span
            >
            <span
              v-if="scope.row.is_adopt == 1 && scope.row.is_groud == 2"
              class="success_font"
              @click="operationData(scope.row.id, 1)"
              >上架</span
            >
            <span
              v-if="scope.row.is_adopt == 2"
              class="refrsh_font"
              style="margin-right: 10px"
              @click="editData(scope.row.id)"
              >修改</span
            >
            <span
              v-if="scope.row.is_adopt == 2"
              class="refrsh_font"
              @click="againSubmit(scope.row.id)"
              >重新提交</span
            >
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <div class="management_reportscreen">
        <div class="management_footer">
          <div class="management_mar">
            <app-mypaginations
              :pagSize="paginations"
              :allData="manaList"
              @setHandleCurrent="handleCurrent"
              @setHandleSize="handleSize"
              @setPage="setPage"
              @setSize="setSize"
            />
          </div>
        </div>
      </div>
    </div>

    <div>
      <el-dialog
        :title="title"
        @close="clearDialog"
        :visible.sync="dialogFormVisible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
        <el-form :model="subform">
          <el-form-item label="产品名称" class="flex">
            <el-input v-model="subform.value1" placeholder="请输入"></el-input>
          </el-form-item>
          <div style="margin-left: 2px">
            <span style="margin-right: 2px; color: red">*</span>
            <span>产品图片</span>
          </div>
          <div style="margin-top: 10px">
            <el-upload
              ref="uploadfiles"
              list-type="picture-card"
              :file-list="uploadList"
              class="avatar-uploader"
              action="#"
              accept=".jpg,.jpeg,.png"
              :multiple="true"
              :limit="1"
              name="file"
              :auto-upload="false"
              :on-exceed="handleExceed"
              :on-remove="handleRemove"
              :on-change="changeUpload"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>
          <el-dialog
            title="图片剪裁"
            :visible.sync="dialogVisible"
            append-to-body
            :close-on-click-modal="false"
            :before-close="cancleFinish"
          >
            <div class="cropper-content">
              <div class="cropper" style="text-align: center">
                <vueCropper
                  ref="cropper"
                  :img="option.img"
                  :outputSize="option.size"
                  :outputType="option.outputType"
                  :info="true"
                  :full="option.full"
                  :canMove="option.canMove"
                  :canMoveBox="option.canMoveBox"
                  :original="option.original"
                  :autoCrop="option.autoCrop"
                  :fixed="option.fixed"
                  :fixedNumber="option.fixedNumber"
                  :centerBox="option.centerBox"
                  :infoTrue="option.infoTrue"
                  :fixedBox="option.fixedBox"
                ></vueCropper>
              </div>
            </div>
            <div slot="footer" class="dialog-footer">
              <el-button @click="cancleFinish">取 消</el-button>
              <el-button type="primary" @click="finish('blob')">确认</el-button>
            </div>
          </el-dialog>

          <el-form-item label="适用地区" class="flex mat_10" required>
            <el-select
              v-model="subform.value2"
              placeholder="请选择"
              clearable
              @clear="clearClass5"
              multiple
              @remove-tag="removetag"
            >
              <el-option
                v-for="(item, index) in options5"
                :value="item.id"
                :label="item.name"
                :disabled="item.disabled"
                :key="index"
                @click.native="setMultiple(item)"
              >
              </el-option>
            </el-select>
            <span style="margin-left: 16px; color: red" v-if="flag"
              >(选择全省后别的地区选择作废，适用地区为全省)</span
            >
          </el-form-item>
          <el-form-item label="产品类型" class="flex mat_10" required>
            <el-select v-model="subform.value3" placeholder="不限">
              <el-option
                v-for="(item, index) in options1"
                :value="item.cate_name"
                :key="index"
                @click.native="getCateId(item, 1)"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="担保方式" class="flex mat_10" required>
            <el-select v-model="subform.value4" placeholder="不限">
              <el-option
                v-for="(item, index) in options3"
                :value="item.cate_name"
                :key="index"
                @click.native="getCateId(item, 2)"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="贷款渠道" class="flex mat_10" required>
            <el-select v-model="subform.value5" placeholder="不限">
              <el-option
                v-for="(item, index) in options2"
                :key="index"
                :value="item.cate_name"
                @click.native="getCateId(item, 3)"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="产品标签" class="flex mat_10" required>
            <el-select v-model="subform.value6" placeholder="不限">
              <el-option
                v-for="(item, index) in options4"
                :key="index"
                :value="item.cate_name"
                @click.native="getCateId(item, 4)"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="融资额度（万）" required class="flex center">
            <el-col :span="11">
              <el-form-item prop="date1">
                <el-input
                  v-model="subform.value7"
                  placeholder="请输入数字"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col class="line" :span="2">-</el-col>
            <el-col :span="11">
              <el-form-item prop="date2">
                <el-input
                  v-model="subform.value8"
                  placeholder="请输入数字"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item label="融资期限（月）" required class="flex center">
            <el-col :span="11">
              <el-form-item prop="date1">
                <el-input
                  v-model="subform.value9"
                  placeholder="请输入数字"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col class="line" :span="2">-</el-col>
            <el-col :span="11">
              <el-form-item prop="date2">
                <el-input
                  v-model="subform.value10"
                  placeholder="请输入数字"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item label="利率（%）" required class="flex center">
            <el-col :span="11">
              <el-form-item prop="date1">
                <el-input
                  v-model="subform.value11"
                  placeholder="请输入数字"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col class="line" :span="2">-</el-col>
            <el-col :span="11">
              <el-form-item prop="date2">
                <el-input
                  v-model="subform.value12"
                  placeholder="请输入数字"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>

          <el-form-item label="产品信息" required class="flex">
            <el-input
              type="textarea"
              v-model="subform.value15"
              class="managment_textarea"
              :autosize="{ minRows: 5, maxRows: 99 }"
              maxlength="1000"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="备注" required class="flex aaa">
            <el-input
              type="textarea"
              v-model="subform.value16"
              class="managment_textarea"
              :autosize="{ minRows: 5, maxRows: 99 }"
              maxlength="1000"
              show-word-limit
              style="margin-left: 26px"
            ></el-input>
          </el-form-item>

          <div>
            <div style="display: inline-block; width: 100px">申请文件设置</div>
            <el-button @click="addDomain(subform.file_type)"
              >添加文件类型</el-button
            >
          </div>
          <el-form-item
            v-for="(type, index) in subform.file_type"
            :label="'类型' + index"
            :key="type.index"
            :props="'file_type.' + index + '.type'"
            :rules="{
              required: true,
              message: '类型不能为空',
              trigger: 'blur',
            }"
          >
            <el-select v-model="type.explain_id" placeholder="请选择类型">
              <el-option
                v-for="item in list"
                :key="item.explain_id"
                :label="item.explain_name"
                :value="item.explain_id"
              ></el-option>
            </el-select>
            <el-button style="width: 100px" @click.prevent="removeDomain(type)"
              >删除</el-button
            >
          </el-form-item>
          <el-form ref="form" :model="form" label-width="67px">
            <el-form-item label="其他资料:">
              <el-input v-model="file_explain" type="textarea"></el-input>
            </el-form-item>
          </el-form>
          <!--  -->
        </el-form>
        <template> </template>
        <div slot="footer" class="dialog-footer">
          <el-button @click="clearDialog">取 消</el-button>
          <el-button type="primary" @click="$throttle(addProduct)">{{
            addoreidt == 1 ? "发布产品" : "修改产品"
          }}</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { Loading } from "element-ui";
import MyPaginations from "@/components/MyPaginations/mypaginations.vue";

export default {
  components: {
    "app-mypaginations": MyPaginations,
  },
  data() {
    return {
      addLonLatRules: [
        { required: true, message: "请输入文件类型", trigger: "blur" },
      ], //经纬度必填],
      // 裁剪组件的基础配置option
      option: {
        img: "", // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 0.8, // 裁剪生成图片的质量
        outputType: "jpeg", // 裁剪生成图片的格式
        canScale: false, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        // autoCropWidth: 300, // 默认生成截图框宽度
        // autoCropHeight: 200, // 默认生成截图框高度
        fixedBox: true, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [5, 2], // 截图框的宽高比例
        full: true, // 是否输出原图比例的截图
        canMoveBox: true, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: true, // 截图框是否被限制在图片里面
        infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      file_explain: "",
      list: [], //请选择活动区域
      title: "添加产品",
      multiple: false,
      dialogVisible: false,
      paginations: {
        page_index: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        page_sizes: [10, 20, 50, 90], //每页显示多少条
        layout: "total, sizes, prev, pager, next, jumper", // 翻页属性
      },
      cityList: [],
      cityListProps: {
        value: "id", //匹配响应数据中的id
        label: "name", //匹配响应数据中的name
        children: "chilren", //匹配响应数据中的children
      },
      form: {
        name: "",
        min_quota: "",
        max_quota: "",
        min_rate: "",
        max_rate: "",
        region_id: "",
        cpstatus: "",
      },
      value: [],
      textList: "",
      options: [
        {
          value: "待审核",
          id: 0,
        },
        {
          value: "已通过",
          id: 1,
        },
        {
          value: "未通过",
          id: 2,
        },
      ],
      dialogFormVisible: false,
      testInfo: {
        headers: [],
        headerKey: "",
        headerVal: "",
      },
      subform: {
        value1: "",
        value2: [],
        value3: "",
        value4: "",
        value5: "",
        value6: "",
        value7: "",
        value8: "",
        value9: "",
        value10: "",
        value11: "",
        value12: "",
        value13: "",
        value14: "",
        value15: "",
        value16: "",
        file_type: [
          {
            file_explain: "",
            type: 1,
          },
        ],
      },
      options1: [],
      options2: [],
      options3: [],
      options4: [],
      uploadList: [],
      add_img: "",
      cate_id: "",
      guarantee_id: "",
      channel_id: "",
      label_id: "",
      manaList: [],
      count: 0,
      listsItem: [],
      status: "",
      nowTime: 0,
      lastTime: 0,
      options5: [],
      options6: [],
      fileName: "",
      loadElemnt: "",
      flag: false,
      addressId: "",
      addoreidt: 1,
      editId: "",
    };
  },
  methods: {
    getDemandNum() {
      const eleToken = localStorage.getItem("eleToken");
      console.log("打印", eleToken);
      this.$get("/explain_lst", { token: eleToken, type: 1 }).then((res) => {
        let list = res.data.result;
        this.list = list;
      });
    },

    addDomain(v) {
      this.subform.file_type.push({
        type: v.length,
        file_explain: "",
        explain_id: undefined,
      });
    },
    removeDomain(item) {
      var index = this.subform.file_type.indexOf(item);
      if (index !== -1) {
        this.subform.file_type.splice(index, 1);
      }
    },

    clearDialog() {
      this.initAll();
      this.dialogFormVisible = false;
    },
    // 修改产品
    editData(id) {
      let data = {
        token: localStorage.eleToken ? localStorage.eleToken : "",
        id,
      };

      this.$post("/product_lst_d", data).then((res) => {
        console.log(res);
        if (res.data.status == 1) {
          this.subform.value1 = res.data.result.name;
          this.add_img = res.data.result.gallery;
          this.uploadList.push({ url: this.add_img });
          this.subform.value7 = res.data.result.min_quota;
          this.subform.value8 = res.data.result.max_quota;
          this.subform.value9 = res.data.result.min_term;
          this.subform.value10 = res.data.result.max_term;
          this.subform.value11 = res.data.result.min_rate;
          this.subform.value12 = res.data.result.max_rate;
          // this.subform.value13 = res.data.result.min_credit;
          // this.subform.value14 = res.data.result.max_credit;
          this.subform.value15 = res.data.result.comment;
          this.file_explain =
            res.data.result.file_type[
              res.data.result.file_type.length - 1
            ].file_explain;

          this.subform.file_type = res.data.result.file_type.filter(
            (item) => item.explain_id != 0
          );
          this.cate_id = res.data.result.cate_id;
          this.guarantee_id = res.data.result.guarantee_id;
          this.channel_id = res.data.result.channel_id;
          this.label_id = res.data.result.label_id;

          this.$post("/qh_city_lst", {}).then((qhr) => {
            if (qhr.data.result.length > 0) {
              qhr.data.result.unshift({
                id: 3126,
                name: "全省",
              });

              qhr.data.result.map((item) => {
                this.$set(item, "disabled", false);
              });

              this.options5 = qhr.data.result;
              console.log("qhr.data.result", qhr.data.result);
              res.data.result.region_id.map((item) => {
                qhr.data.result.map((reg) => {
                  if (item == reg.id) {
                    console.log("itemitem", item);
                    this.subform.value2.push(reg.name);
                  }
                });
              });
            } else {
              this.options5 = [];
            }
          });

          this.subform.value16 = res.data.result.remark;

          this.getClass1();
          this.getClass2();
          this.getClass3();
          this.getClass4();

          this.editId = res.data.result.id;
          this.addoreidt = 2;
          this.title = "修改产品";

          this.dialogFormVisible = true;
        }
      });
    },
    removetag(tag) {
      if (tag == 3126) {
        this.flag = false;
        this.options5.map((item, index) => {
          this.$set(item, "disabled", false);
        });
      }
    },
    setMultiple(item) {
      if (item.id == 3126) {
        this.flag = true;
        this.options5.map((item, index) => {
          this.$set(item, "disabled", true);
        });
      }
    },
    // 点击裁剪，这一步是可以拿到处理后的地址
    finish(type) {
      this.loadElemnt = Loading.service({
        fullscreen: true,
        text: "正在裁剪，请稍等...",
      });

      console.log("finish", type);
      let _this = this;
      let formData = new FormData();
      // 输出
      if (type === "blob") {
        this.$refs.cropper.getCropBlob((data) => {
          formData.append("file", data, this.fileName);

          this.httpreq1(formData);
        });
      }
    },
    cancleFinish(type) {
      console.log(4564, this.flagPic);
      this.dialogVisible = false;
      this.option.img = "";
      this.fileName = "";

      this.add_img = "";

      this.uploadList = [];
    },
    changeUpload(file, fileList) {
      let testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = testmsg === "jpg";
      const extension1 = testmsg === "jpeg";
      const extension2 = testmsg === "png";

      if (!extension && !extension1 && !extension2) {
        this.$message({
          message: "上传文件只能是.jpg、.jpeg、.png格式!",
          type: "warning",
          duration: 1500,
        });
        this.option.img = "";

        this.add_img = "";

        this.uploadList = [];
        return false;
      }

      let url = URL.createObjectURL(file.raw);

      // 上传成功后将图片地址赋值给裁剪框显示图片
      this.$nextTick(() => {
        this.option.img = url;
        this.fileName = file.name;

        this.dialogVisible = true;
      });
    },
    operationData(id, type) {
      this.nowTime = new Date();

      let font = type == 1 ? "是否上架?" : "是否下架?";

      if (this.nowTime - this.lastTime > 1500) {
        this.$confirm(font, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          closeOnClickModal: false,
          type: "warning",
        })
          .then(() => {
            let data = {
              id,
              token: localStorage.eleToken ? localStorage.eleToken : "",
              groud: type,
            };

            this.$post("pro_state", data).then((res) => {
              let type = res.data.status == 1 ? "success" : "error";

              this.$message({
                message: res.data.msg,
                type,
              });

              if (res.data.status == 1) {
                this.getList();
              }
            });

            this.lastTime = this.nowTime;
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });

            this.lastTime = this.nowTime;
          });
      }
    },
    againSubmit(id) {
      this.nowTime = new Date();

      if (this.nowTime - this.lastTime > 1500) {
        this.$confirm("是否重新提交?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          closeOnClickModal: false,
          type: "warning",
        })
          .then(() => {
            let data = {
              id,
              token: localStorage.eleToken ? localStorage.eleToken : "",
            };

            this.$post("product_adopt", data).then((res) => {
              let type = res.data.status == 1 ? "success" : "error";

              this.$message({
                message: res.data.msg,
                type,
              });

              if (res.data.status == 1) {
                this.getList();
              }
            });

            this.lastTime = this.nowTime;
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });

            this.lastTime = this.nowTime;
          });
      }
    },
    initAdd(v) {
      this.addoreidt = 1;
      this.title = "添加产品";

      this.initAll();

      this.dialogFormVisible = true;
      //添加产品有默认的类型
      // this.subform.file_type.push({
      //   type: v.length,
      //   file_explain: '',
      // });
    },
    initAll() {
      this.subform.value1 = "";
      this.add_img = "";
      this.subform.value3 = "";
      this.subform.value4 = "";
      this.subform.value5 = "";
      this.subform.value6 = "";
      this.subform.value7 = "";
      this.subform.value8 = "";
      this.subform.value9 = "";
      this.subform.value10 = "";
      this.subform.value11 = "";
      this.subform.value12 = "";
      // this.subform.value13 = '';
      // this.subform.value14 = '';
      this.subform.value15 = "";
      this.subform.value16 = "";
      this.subform.file_type = [];
      this.cate_id = "";
      this.guarantee_id = "";
      this.channel_id = "";
      this.label_id = "";
      this.subform.value2 = [];
      this.addressId = "";

      this.uploadList = [];
      this.getClass1();
      this.getClass2();
      this.getClass3();
      this.getClass4();
    },
    handleChange(value) {
      console.log(value);
    },
    // 添加产品 || 修改
    addProduct() {
      // 添加产品、
      var arr1 = [];
      var arr2 = [];

      let else_data = [...this.subform.file_type];

      if (this.file_explain) {
        else_data.push({
          type: this.subform.file_type.length,
          file_explain: this.file_explain,
          explain_id: "",
        });
      }

      console.log("file_type", this.subform.file_type);

      if (this.subform.file_type.length == 0) {
        this.$notify.error({
          title: "错误",
          // position: 'center',
          message: "请填写完整的产品信息",
        });
      } else {
        if (arr1.length !== arr2.length) {
          this.$notify.error({
            title: "错误",
            // position: 'center',
            message: "请填写完整的产品信息",
          });
        } else {
          if (this.addoreidt == 1) {
            let data = {
              token: localStorage.eleToken ? localStorage.eleToken : "",
              name: this.subform.value1,
              gallery: this.add_img,
              min_quota: this.subform.value7,
              max_quota: this.subform.value8,
              min_term: this.subform.value9,
              max_term: this.subform.value10,
              min_rate: this.subform.value11,
              max_rate: this.subform.value12,
              // min_credit: this.subform.value13,
              // max_credit: this.subform.value14,
              comment: this.subform.value15,
              file_type: else_data,
              cate_id: this.cate_id,
              guarantee_id: this.guarantee_id,
              channel_id: this.channel_id,
              label_id: this.label_id,

              region_id:
                this.subform.value2.indexOf(3126) == -1
                  ? this.subform.value2
                  : [3126],
              remark: this.subform.value16,
            };

            console.log("data", data);
            this.$post("/product_add", data).then((res) => {
              let type = res.data.status == 1 ? "success" : "error";

              this.$message({
                message: res.data.msg,
                type,
                duration: 2000,
              });

              if (res.data.status == 1) {
                this.dialogFormVisible = false;

                this.getList();
              }
            });
          }
          // 修改产品
          if (this.addoreidt == 2) {
            this.subform.value2.map((item, index) => {
              this.options5.map((reg) => {
                if (item == reg.name) {
                  this.subform.value2[index] = reg.id;
                }
              });
            });

            let data = {
              token: localStorage.eleToken ? localStorage.eleToken : "",

              name: this.subform.value1,
              gallery: this.add_img,
              min_quota: this.subform.value7,
              max_quota: this.subform.value8,
              min_term: this.subform.value9,
              max_term: this.subform.value10,
              min_rate: this.subform.value11,
              max_rate: this.subform.value12,
              // min_credit: this.subform.value13,
              // max_credit: this.subform.value14,
              comment: this.subform.value15,
              file_type: else_data,

              cate_id: this.cate_id,
              guarantee_id: this.guarantee_id,
              channel_id: this.channel_id,
              label_id: this.label_id,

              region_id:
                this.subform.value2.indexOf(3126) == -1
                  ? this.subform.value2
                  : [3126],
              remark: this.subform.value16,
              id: this.editId,
            };

            this.$post("/product_edit", data).then((res) => {
              let type = res.data.status == 1 ? "success" : "error";
              this.$message({
                message: res.data.msg,
                type,
                duration: 2000,
              });
              if (res.data.status == 1) {
                this.dialogFormVisible = false;

                this.initAll();
                this.getList();
              }
            });
          }
        }
      }
    },

    changeAddress() {},
    // 适用地区
    getQhCityList() {
      let data = {};

      this.$post("/qh_city_lst", data).then((res) => {
        if (res.data.result.length > 0) {
          res.data.result.unshift({
            id: 3126,
            name: "全省",
          });

          this.options6 = res.data.result;

          res.data.result.map((item) => {
            this.$set(item, "disabled", false);
          });

          this.options5 = res.data.result;
        } else {
          this.options5 = [];
        }

        console.log("this.options5", this.options5);
      });
    },
    clearClass5() {
      this.flag = false;
      this.addressId = "";
      this.options5.map((item, index) => {
        this.$set(item, "disabled", false);
      });
    },
    clearClass() {
      this.addressId = "";

      this.searchData();
    },
    setAddressId(id) {
      this.addressId = id == 3126 ? "" : id;

      this.searchData();
    },
    // 分类 id
    getCateId(item, type) {
      if (type == 1) {
        this.cate_id = item.id;
      }

      if (type == 2) {
        this.guarantee_id = item.id;
      }

      if (type == 3) {
        this.channel_id = item.id;
      }

      if (type == 4) {
        this.label_id = item.id;
      }
    },
    // 分类   1:产品分类  2：担保  3： 渠道  4：标签
    getClass1() {
      this.$get("/pro_cate", {
        type: 1,
      }).then((res) => {
        if (res.data.status == 1) {
          this.options1 = !res.data.result ? [] : res.data.result;

          if (this.addoreidt == 2) {
            res.data.result.map((item) => {
              if (item.id == this.cate_id) {
                this.subform.value3 = item.cate_name;
              }
            });
          }
        }
      });
    },
    getClass2() {
      this.$get("/pro_cate", {
        type: 3,
      }).then((res) => {
        this.options2 = !res.data.result ? [] : res.data.result;

        if (this.addoreidt == 2) {
          res.data.result.map((item) => {
            if (item.id == this.channel_id) {
              this.subform.value5 = item.cate_name;
            }
          });
        }
      });
    },
    getClass3() {
      this.$get("/pro_cate", {
        type: 2,
      }).then((res) => {
        this.options3 = !res.data.result ? [] : res.data.result;

        if (this.addoreidt == 2) {
          res.data.result.map((item) => {
            if (item.id == this.guarantee_id) {
              this.subform.value4 = item.cate_name;
            }
          });
        }
      });
    },
    getClass4() {
      this.$get("/pro_cate", {
        type: 4,
      }).then((res) => {
        this.options4 = !res.data.result ? [] : res.data.result;

        if (this.addoreidt == 2) {
          res.data.result.map((item) => {
            if (item.id == this.label_id) {
              this.subform.value6 = item.cate_name;
            }
          });
        }
      });
    },
    handleBeforeUpload(file) {
      let testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = testmsg === "jpg";
      const extension1 = testmsg === "jpeg";
      const extension2 = testmsg === "png";

      if (!extension && !extension1 && !extension2) {
        this.$message({
          message: "上传文件只能是.jpg、.jpeg、.png格式!",
          type: "warning",
          duration: 1500,
        });
        return false;
      }
    },
    handleExceed() {
      this.$message({
        message: "最大上传数量为1张!",
        type: "warning",
        duration: 1500,
      });
    },
    handleRemove() {
      this.uploadList = [];
      this.add_img = "";
    },
    httpreq1(formData) {
      this.$post("upload_file", formData).then((res) => {
        this.add_img = res.data.address;
        this.dialogVisible = false;

        let picObj = {
          url: this.add_img,
        };
        this.uploadList.push(picObj);

        this.loadElemnt.close();
      });
    },
    setPage(data) {
      this.paginations.page_index = data;

      this.getList();
    },
    setSize(data) {
      this.paginations.page_index = 1;
      this.paginations.page_size = data;

      this.getList();
    },
    handleCurrent(item) {
      this.listsItem = item;
    },
    handleSize(item) {
      this.listsItem = item;
    },
    //重置分页
    resetPaginations() {
      // 总页数
      this.paginations.total = this.count;
      // 设置默认分页数据
      this.listsItem = this.manaList.filter((item, index) => {
        return index < this.paginations.page_size;
      });
    },
    setCpStatus(item) {
      this.status = item.id;
    },
    clearCpStatus() {
      this.status = "";
    },
    searchData() {
      this.paginations.page_index = 1;

      this.getList();
    },
    getList() {
      let data = {
        token: localStorage.eleToken ? localStorage.eleToken : "",
        page: this.paginations.page_index,
        limit: this.paginations.page_size,
        name: this.form.name,
        mark: "",
        is_adopt: this.status,
        min_quota: this.form.min_quota,
        max_quota: this.form.max_quota,
        min_rate: this.form.min_rate,
        max_rate: this.form.max_rate,
        region_id: this.addressId,
      };

      console.log("data", data);

      this.$post("/product_lst", data).then((res) => {
        this.manaList = res.data.result ? res.data.result : [];

        this.count = res.data.count ? res.data.count : 0;

        this.resetPaginations();
      });
    },
  },
  mounted() {
    this.getQhCityList();
    this.getList();

    this.getDemandNum();
  },
};
</script>

<style scoped>
/deep/.aaa .el-form-item__label:before {
  display: none !important;
}
.managment_textarea {
  width: 440px;
}

/*分页*/
.management_reportscreen {
  /* position: absolute;
  bottom: 0.5%;
  width: 100%;*/
  margin-top: 14px;
  display: flex;
  justify-content: flex-end;
}

.management_footer {
  min-width: 402px;
  height: 45px;
  background-color: #fff;
  display: flex;
  align-items: center;
}

.management_mar {
  width: 100%;
}

.el-pagination {
  display: flex;
}

.el-pagination .el-select .el-input {
  height: 100%;
}

.el-select {
  height: 100%;
}

.el-pagination__jump {
  display: flex !important;
}

/deep/.red {
  color: #ff9d00;
}

/deep/.blue {
  color: #0d8eff;
}
.div-inline {
  display: inline-block;
}

.btn {
  display: inline-block;
}
.text {
  display: inline-block;
}
.cropper-content .cropper {
  width: auto;
  height: 300px;
}
</style>
